<!--
 * @Date: 2021年11月18日10:34:27
 * @Author: Jeason
 * @LastEditors: Jeason
 * @LastEditTime: 2021年11月18日10:34:31
 * @Description: 意见反馈 列表
 * @FilePath:
-->
<template>
  <div class='JZContainer'>
    <QueryForm label-position="right" ref="JZForm" :model="JZForm" label-width="112px" class="content_block" @search='handleSearch' @reset="handleReset">
      <el-form-item label="意见单编号：" prop="code" >
        <el-input placeholder="请输入意见单编号" v-model.trim="JZForm.code" clearable></el-input>
      </el-form-item>
      <el-form-item label="提交人：" prop="submitUserName" >
        <el-input placeholder="请输入提交人" v-model.trim="JZForm.submitUserName" clearable></el-input>
      </el-form-item>
      <el-form-item label="反馈模块：" prop="module" >
        <el-select v-model="JZForm.module" placeholder="请选择反馈模块" @change='handleSearch'>
          <el-option
            v-for="mod in moduleLists"
            :key="mod.value"
            :label="mod.label"
            :value="mod.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：" prop="status" >
        <el-select v-model="JZForm.status" placeholder="请选择状态" @change='handleSearch'>
          <el-option
            v-for="mod in statusOptions"
            :key="mod.value"
            :label="mod.label"
            :value="mod.value">
          </el-option>
        </el-select>
      </el-form-item>
    </QueryForm>
    <TableWithPagination
      :tableData="targetLists"
      class="content_block"
      :columnList="columnList"
      :leftToolbar="tableButtons"
      :pagination.sync="pagination"
      :sortInfo.sync="updateSortInfo"
      @current-change="getTargetLists"
      @sort-change="getTargetLists"
    ></TableWithPagination>
  </div>
</template>

<script>
import InfoMixin from '@/mixins/infoMixin.js';
import Feedback from '@/api/feedback';
export default {
  name: 'FeedbackLists',
  mixins: [
    InfoMixin,
  ],
  methods: {
    handleReset () {
      this.JZForm = {
        status: '',
        module: '',
        submitUserName: '',
        code: '',
      };
      this.handleSearch();
    },
    handleSearch () {
      this.pagination.currentPage = 1;
      this.getTargetLists();
    },
    getTargetLists () {
      let currentParams = this.getCurrentParams();
      Feedback.feedbackQuery(currentParams).then(this.getTargetListsSuccessFun).catch(() => {
        this.targetLists = [];
        this.pagination.total = 0;
      });
    },
    getTargetListsSuccessFun (jsonData) {
      let currentLists = [];
      if (jsonData.heads.code === 200) {
        currentLists = jsonData.body.list;
      }
      this.targetLists = currentLists;
      this.pagination.total = jsonData.body.total;
    },
    getCurrentParams () {
      return {
        pageNum: this.pagination.currentPage,
        pageSize: this.pagination.pageSize,
        ...this.JZForm,
        orderBy: [{
          orderByProperty: 'lastUpdateTime',
          orderByType: 'DESC',
        }],
      };
    },
    handleDetail (info, index) {
      let name = index === 2 ? 'ViewFeedbackLists' : 'EditFeedbackLists';
      this.$router.push({
        name: name,
        query: {
          isStatus: index === 2 ? 'isViewing' : 'isEditing',
          id: info.id,
        },
      });
    },
    addNew () {
      this.$router.push({
        name: 'AddFeedbackLists',
      });
    },
    handleDelete (info) {
      this.$confirmAlert(`确认删除[${info.code}]意见反馈吗？`).then(() => {
        Feedback.feedbackDelete({id: info.id}).then(jsonData => {
          if (jsonData.heads.code === 200) {
            this.handleSearch();
          } else {
            this.$JZShowAlert(jsonData.heads.message, 'error');
          }
        });
      });
    },
    handleSubmit (info) {
      this.$confirmAlert(`确认提交[${info.code}]意见反馈吗？`).then(() => {
        Feedback.feedbackSubmit({id: info.id}).then(jsonData => {
          if (jsonData.heads.code === 200) {
            this.handleSearch();
          } else {
            this.$JZShowAlert(jsonData.heads.message, 'error');
          }
        });
      });
    },
  },
  data () {
    return {
      JZForm: {
        status: '',
        module: '',
        submitUserName: '',
        code: '',
      },
      moduleLists: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '工单中心',
          value: '1',
        },
        {
          label: '医院管理',
          value: '2',
        },
        {
          label: '设备管理',
          value: '3',
        },
        {
          label: '系统管理',
          value: '4',
        },
      ],
      statusOptions: [
        {
          label: '全部',
          value: '',
        },
        {
          label: '草稿 ',
          value: '1',
        },
        {
          label: '待处理',
          value: '2',
        },
        {
          label: '已处理',
          value: '3',
        },
      ],
      targetLists: [],
      tableButtons: [
        {
          name: '新建意见反馈', // 按钮内容(必填)
          status: 'primary', // 按钮样式
          size: 'small', // 大小尺寸
          code: 'addNew', // code(必填)
          icon: 'vxe-icon--plus', // 按钮内部icon
          permission: '', // 权限码，若为空字符串或不设置则表示不进行权限控制
          click: this.addNew, // 点击回调事件
        },
      ],
      columnList: [
        {
          tooltip: true,
          label: '意见单编号',
          minWidth: '150px',
          prop: 'code',
          render: (h, {row}) => {
            let actions = [];
            actions.push(h('span', {
              domProps: {
                className: 'blue',
              },
              on: {
                click: this.handleDetail.bind(this, row, 2),
              },
            }, row.code));
            return h('div', actions);
          },
        },
        {
          tooltip: true,
          label: '提交人',
          minWidth: '110px',
          prop: 'submitUserName',
        },
        {
          tooltip: true,
          label: '手机号',
          minWidth: '150px',
          prop: 'mobilephone',
        },
        {
          tooltip: true,
          label: '提交时间',
          minWidth: '110px',
          prop: 'submitTime',
          format: this.$options.filters['date-format'],
        },
        {
          tooltip: true,
          label: '反馈模块',
          minWidth: '120px',
          prop: 'moduleDesc',
        },
        {
          tooltip: true,
          label: '问题描述',
          minWidth: '180px',
          prop: 'remark',
        },
        {
          tooltip: true,
          label: '状态',
          minWidth: '100px',
          prop: 'statusDesc',
        },
        {
          tooltip: true,
          label: '意见单处理结果',
          minWidth: '150px',
          prop: 'handleResult',
        },
        {
          tooltip: true,
          label: '操作',
          minWidth: '110px',
          prop: 'id',
          fixed: 'right',
          render: (h, {row}) => {
            let actions = [];
            if (row.status === '1') {
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleSubmit.bind(this, row),
                },
              }, '提交'));
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleDetail.bind(this, row, 1),
                },
              }, '编辑'));
              actions.push(h('span', {
                domProps: {
                  className: 'blue',
                },
                on: {
                  click: this.handleDelete.bind(this, row),
                },
              }, '删除'));
            }
            return h('div', actions);
          },
        },
      ],
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */

</style>
